import { createFileRoute } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfAuthenticatedUserRequired } from "@app/lib";
import { HomePage } from "@pages/home";

export const Route = createFileRoute("/")({
  beforeLoad: async ({ location }) => {
    !isLobbyDemoMode() &&
      (await redirectIfAuthenticatedUserRequired({
        search: {
          redirect: location.href,
        },
      }));
  },
  component: HomePage,
  onError: console.error,
});
