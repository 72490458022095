import { FortuneWheel } from "@entities/fortune-wheel";
import { FortuneWheelButton } from "@features/fortune-wheel-button";
import { GameSearch } from "@features/game-search";
import { useMobile } from "@shared/lib";
import { BannerPanel } from "@widget/banner-panel";
import { CategoryList } from "@widget/category-list";
import { Footer } from "@widget/footer";
import { GameLists } from "@widget/game-lists";
import { Header } from "@widget/header";
import { JackpotTickerSlots } from "@widget/jackpot-ticker-slots";
import { LogInModal } from "@widget/log-in-modal";
import { ProviderList, ProvidersModal } from "@widget/provider-list";

export function HomePage() {
  const isMobile = useMobile();
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  return (
    <>
      <Header />
      <main className="max-w-screen-3xl mx-auto mobile-only:contents">
        <div className="px-5 mobile-only:contents">
          <>
            <LogInModal />
            <ProvidersModal />
          </>

          <div className="mt-3 lg:mt-5">
            <BannerPanel />
          </div>

          <div className="mt-3 lg:mt-5">
            <JackpotTickerSlots />
          </div>

          {isMobile ? (
            <>
              <div className="p-3">
                <div className="rounded-xs bg-athens-gray dark:bg-outer-space p-3 flex-grow">
                  <GameSearch />
                </div>

                {isFortuneWheelAvailable && (
                  <div className="mt-3">
                    <FortuneWheelButton.Mobile />
                  </div>
                )}

                <div className="mt-3">
                  <ProviderList />
                </div>
              </div>

              <CategoryList />
              <div className="mt-3" id="navigate-category-anchor">
                <GameLists />
              </div>
            </>
          ) : (
            <div
              className="grid grid-cols-[18.5rem_minmax(0,auto)] gap-5 mt-5"
              id="navigate-category-anchor"
            >
              <CategoryList />
              <div className="grow">
                <ProviderList />
                <GameLists className="mt-5" />
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer className="mt-3 lg:mt-5" />
    </>
  );
}
