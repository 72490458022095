import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createRootRouteWithContext,
  defer,
  Navigate,
  Outlet,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect } from "react";

import {
  ensureUserIsAuthenticated,
  validateSearchModal,
  validateSearchProfileSection,
} from "@app/lib";
import { loadFortuneWheelSettings } from "@app/lib/loaders";
import { TakeBonusButton } from "@features/take-bonus-button";
import { ContactsModal } from "@modals/contacts-modal/contacts-modal";
import { TakeBonusModal } from "@modals/take-bonus/take-bonus-modal";
import { emitter } from "@shared/lib";
import { ErrorModal } from "@shared/ui/error-modal/error-modal";
import { ProfileModal, type TProfileSection } from "@widget/profile-modal";

import { WarningModal } from "@features/warning-modal";
import type { QueryClient } from "@tanstack/react-query";

type GlobalSearch = {
  modal?: string;
  cid: number;
  pid: number;
  game: string;
  redirect: string;
  profileSection?: TProfileSection;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  onError: console.error,
  beforeLoad: async ({ cause }) => cause === "enter" && (await ensureUserIsAuthenticated()),
  loader: async ({ context }) => defer(loadFortuneWheelSettings(context.queryClient)),
  notFoundComponent: () => <Navigate to="/" replace params={{}} />,
  validateSearch: (search: Record<string, unknown>): GlobalSearch => ({
    modal: validateSearchModal(search),
    cid: search.cid as number,
    pid: search.pid as number,
    game: search.game as string,
    redirect: search.redirect as string,
    profileSection: validateSearchProfileSection(search),
  }),
});

function RootComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    return emitter.on("PLAYER_LOGOUT", () => navigate({ to: "/", replace: true }));
  }, [navigate]);

  return (
    <>
      <ProfileModal />
      <TakeBonusModal />
      <ContactsModal />
      <WarningModal />
      <ErrorModal />

      <TakeBonusButton className="lg:right-10 lg:bottom-10 fixed bottom-5 z-[999] mobile-only:px-3 mobile-only:w-full" />
      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </>
  );
}
