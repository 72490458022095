import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";

import { Logo, SVGIcon } from "@shared/ui";

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <footer className={clsx(className, "bg-athens-gray dark:bg-outer-space")}>
      <div className="lg:max-w-screen-3xl mx-auto lg:p-5 p-3">
        <div className="lg:contents flex flex-col gap-2">
          <div className="lg:gap-5 flex-c-sb gap-3">
            <Link className="shrink-0" to="/">
              <Logo className="lg:h-11 h-5" />
            </Link>

            <div
              className="lg:text-base lg:gap-2.5 gap-1 flex-center text-2xs text-keppel font-medium cursor-pointer whitespace-nowrap dark:text-java"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              {/*for formatjs same key*/}
              <span className="hidden">
                {$t({ defaultMessage: "18+" })}
                {$t({
                  defaultMessage:
                    // eslint-disable-next-line formatjs/enforce-placeholders
                    "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
                })}
                {$t({ defaultMessage: "game" })}
                {$t({ defaultMessage: "responsibly" })}
              </span>
              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <button
                className="lg:size-8 lg:hover:text-outer-space lg:dark:hover:text-white bg-mercury rounded-rounded flex-center size-5 dark:bg-ebony-clay"
                type="button"
              >
                <SVGIcon className="lg:size-3 size-1.5 -rotate-90" name="arrowRight" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
