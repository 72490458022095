import { createFileRoute, redirect } from "@tanstack/react-router";

import { isLobbyDemoMode, redirectIfUserIsAuthenticated } from "@app/lib/helpers";
import { SignInPage } from "@pages/sign-in";

export const Route = createFileRoute("/sign-in")({
  component: SignInPage,
  onError: console.error,
  beforeLoad: async () => {
    await redirectIfUserIsAuthenticated("/");

    if (isLobbyDemoMode()) {
      throw redirect({
        to: "/",
        replace: true,
        search: {
          modal: "log-in",
        },
      });
    }
  },
});
