import { createFileRoute } from "@tanstack/react-router";

import { redirectIfAuthenticatedUserRequired } from "@app/lib";
import { GamePage } from "@pages/game";

export const Route = createFileRoute("/game/$gameId")({
  beforeLoad: redirectIfAuthenticatedUserRequired,
  component: GamePage,
  onError: console.error,
});
