import { useTranslate } from "@lobby/ocb-intl";

import { Skeleton } from "@shared/ui";

interface IRulesSectionProps {
  rules?: { general: string; daily: string };
}

function RuleContent({
  className,
  title,
  description,
}: {
  className?: string;
  title: string;
  description: string | undefined;
}) {
  return (
    <div className={className}>
      <div className="font-bold text-2xs uppercase mb-3 dark:text-java">{title}</div>
      {description ? (
        <p className="text-3xs whitespace-pre-line">{description}</p>
      ) : (
        <div className="flex flex-col gap-1 w-40 *:dark:bg-big-stone *:h-3 *:w-full">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </div>
  );
}

export function RulesSectionMobile({ rules }: IRulesSectionProps) {
  const { $t } = useTranslate();

  return (
    <div className="bg-keppel/30 border-1 border-keppel rounded-sm p-1 dark:bg-java/30 dark:border-java">
      <div className="bg-alto rounded-inherit flex justify-between gap-14 px-3 py-2 dark:bg-bright-gray">
        <RuleContent title={$t({ defaultMessage: "how it works" })} description={rules?.general} />
        <RuleContent
          className="text-right"
          title={$t({ defaultMessage: "daily wheel rules" })}
          description={rules?.daily}
        />
      </div>
    </div>
  );
}
