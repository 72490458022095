import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { useIsMutating } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";

import { router } from "@app/config";
import { LanguageSelector } from "@features/language-selector";
import { LoginForm } from "@features/log-in-form";
import { ThemeSwitch } from "@features/theme-switch";
import { emitter } from "@shared/lib";
import { Logo } from "@shared/ui";
import { Spin } from "@shared/ui/spin";

export function SignInPage() {
  const { $t } = useTranslate();
  const [isLoadingState, setIsLoadingState] = useState(false);
  const timestampRef = useRef(performance.now());
  const search = useSearch({ strict: false });

  const isLogInPending = useIsMutating({ mutationKey: ["Player.logIn"] }) > 0;

  useEffect(() => {
    const unbind = emitter.on("PLAYER_LOGIN", () => router.history.push(search.redirect || "/"));
    return unbind;
  }, []);

  useEffect(() => {
    if (isLogInPending) {
      timestampRef.current = performance.now();
      setIsLoadingState(isLogInPending);
    } else {
      setTimeout(
        () => setIsLoadingState(isLogInPending),
        Math.max(0, 1000 - (performance.now() - timestampRef.current)),
      );
    }
  }, [isLogInPending]);

  const loadingStateMessage = isLoadingState
    ? `${$t({ defaultMessage: "Loading" })} ${skipIntl("...")}`
    : $t({ defaultMessage: "Log In" });

  return (
    <div className="sign-in-page fixed inset-0">
      <div className="flex-center mobile-only:items-start size-full overflow-y-auto">
        <div className="lg:w-96 h-fit w-64 mobile-only:mt-5">
          <Logo className="lg:h-15 h-11 mx-auto w-fit" />
          <div className="mt-5 font-bold text-xl text-center uppercase">{loadingStateMessage}</div>
          <div className={`${isLoadingState ? "blur-sm" : "blur-none"}`}>
            <LoginForm className="mt-5" mayShowError={!isLoadingState} />
            <hr className="opacity-50 my-7" />
            <div className="flex items-center justify-between">
              <LanguageSelector />
              <ThemeSwitch />
            </div>
          </div>

          <div
            className={`absolute inset-0 flex-center select-none ${isLoadingState ? "visible" : "invisible"}`}
          >
            <Spin className="lg:w-40 w-32" />
          </div>
        </div>
      </div>
    </div>
  );
}
